import { Box } from "@mui/material";
import React from "react";
import { Page } from "../../components/Page";
import { ServicesBlock } from "./ServicesBlock";
import { TopBlock } from "./TopBlock";

export const Home: React.FC = () => {
  return (
    <Page shouldDisplayFooter>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TopBlock />
          <Box pt={2}>
            <iframe
              style={{
                width: "100%",
                height: "auto",
                aspectRatio: "16/9",
              }}
              src="https://www.youtube.com/embed/MV50TOPAatw"
              title="11 Plus Learn and Play - Intro Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Box>
          <ServicesBlock />
        </Box>
      </Box>
    </Page>
  );
};
