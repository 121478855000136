import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../utils/urls";
import { Button } from "../Button";

export const LoginBlock: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}
      pl={1}
    >
      <Button onClick={() => navigate(URLS.REGISTER)} variant="outlined">
        Register
      </Button>
      <Button onClick={() => navigate(URLS.LOGIN)} variant="contained">
        Log in
      </Button>
    </Box>
  );
};
