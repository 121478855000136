import { Box } from "@mui/material";
import React from "react";

export const ServicesBlock: React.FC = () => {
  return (
    <Box
      pt={4}
      pb={2}
      sx={{
        display: "flex",
        maxWidth: 1048,
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      <Box
        component="img"
        sx={{ width: "100%", maxWidth: 524, height: "auto" }}
        src={`${process.env.PUBLIC_URL}/flexible-plans.jpeg`}
      />
      <Box
        sx={{ width: "100%", maxWidth: 524, height: "auto" }}
        component="img"
        src={`${process.env.PUBLIC_URL}/engaging-and-intuitive.jpeg`}
      />
      <Box
        sx={{ width: "100%", maxWidth: 524, height: "auto" }}
        component="img"
        src={`${process.env.PUBLIC_URL}/created-by-experts.jpeg`}
      />
      <Box
        sx={{ width: "100%", maxWidth: 524, height: "auto" }}
        component="img"
        src={`${process.env.PUBLIC_URL}/have-control.jpeg`}
      />
    </Box>
  );
};
