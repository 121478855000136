import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import {
  PACKAGE_CONTENTS,
  PACKAGE_CONTENT_COLOURS,
} from "../../utils/constants";
import { palette } from "../../utils/muiTheme";

export const TopBlock: React.FC = () => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    setInterval(() => {
      setCount((old) => old + 1);
    }, 3000);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Box
        py={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h3" color={palette.lightPurple}>
          11+
        </Typography>
        <Typography variant="h3" color={palette.lightPurple}>
          Learn and Play
        </Typography>
        <Typography variant="body1" color={palette.lightPink}>
          A Fun and Intuitive website which provides
        </Typography>
        <Box
          py={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box pr={2}>
            <Typography
              variant="h4"
              color={palette.deepBlue}
              sx={{
                width: 180,
                p: 1,
                textAlign: "center",
                background: PACKAGE_CONTENT_COLOURS[count % 3],
              }}
            >
              {PACKAGE_CONTENTS[count % 3]}
            </Typography>
          </Box>
          <Typography color={palette.lightPink} variant="body1">
            in one package
          </Typography>
        </Box>
        {/* <Button
          onClick={() => window.open("https://youtu.be/MV50TOPAatw")}
          variant="contained"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PlayCircleIcon fontSize="small" />
            <Box pl={1}>Watch Demo</Box>
          </Box>
        </Button> */}
      </Box>
      <Box
        sx={{ width: "100%", maxWidth: 524, height: "auto" }}
        component="img"
        src="./home-page-top-block.jpeg"
      />
    </Box>
  );
};
