import { AppBar, Box, Button } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../redux/store";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";
import { AccountManager } from "../AccountManager";
import { LoginBlock } from "../LoginBlock";
import { NavBarButton } from "./NavBarButton";

export const NavBar: React.FC = () => {
  const currUser = useTypedSelector((state) => state.user.currUser);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <AppBar
      elevation={0}
      position="static"
      sx={{
        pt: 2,
        backgroundColor: palette.deepBlue,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        p={1}
        display="flex"
        alignItems="center"
        sx={{ height: 48, width: "100%", maxWidth: 1200 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Button onClick={() => navigate(URLS.HOME)}>
            <Box component="img" src={process.env.PUBLIC_URL + "/Logo.png"} />
          </Button>
        </Box>
        <Box
          sx={{
            alignSelf: "flex-end",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <NavBarButton currPath={pathname} url={URLS.HOME} title="Home" />
          {currUser && currUser.isSubscribed && (
            <NavBarButton
              currPath={pathname}
              url={URLS.SUBJECT_LIST}
              title="Subjects"
            />
          )}
          {currUser ? <AccountManager /> : <LoginBlock />}
        </Box>
      </Box>
    </AppBar>
  );
};
