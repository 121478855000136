import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

type Props = {
  title: string;
  url: string;
  currPath: string;
};

export const NavBarButton: React.FC<Props> = ({ url, currPath, title }) => {
  const navigate = useNavigate();

  return (
    <Button
      sx={{
        px: 1,
        borderBottom: url === currPath ? 2 : 0,
        borderRadius: 0,
      }}
      onClick={() => {
        navigate(url);
      }}
      variant="text"
    >
      <Typography variant="body1">{title}</Typography>
    </Button>
  );
};
